/* src/components/Login.css */
.smooth-text {
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
}

input::placeholder {
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
}

/* Overlay styles */
.overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

/* Modal styles */
.modal {
    position: relative;
    width: 90%;
    max-width: 400px;
    max-height: 90%;
    overflow-y: auto;
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
